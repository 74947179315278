<template>
    <div>
        <div class="form-container">
            <!-- <v-form ref="form" lazy-validation > -->
            <v-form ref="form">
                <v-row >
                    <v-col cols=12>
                        <p class="form-title">Precio de compra de la vivienda </p>
                        <p class="form-subtitle">Si no lo conoces exactamente, pon un importe aproximado</p>
                        <v-currency-field
                            v-model="prescoringData.precio_compra"
                            outlined
                            append-icon="mdi-currency-eur"
                            dense
                            :rules="[...ruleRequired,...ruleMin30k]"
                            v-on:keyup="setFilled"
                        ></v-currency-field>
                    </v-col>

                    <v-col cols=12>
                        <p class="form-title">Comunidad autónoma</p>
                        <p class="form-subtitle"> Este dato nos servirá para calcular el importe del ITP (Impuesto de Transmisiones Patrimoniales)</p>
                        <v-autocomplete 
                            outlined
                            v-model="prescoringData.ccaa"
                            :items="vars.ccaa"
                            item-text="name"
                            item-value="code"
                            dense
                            :rules="[...ruleRequired]"
                            v-on:keyup="setFilled"
                        />
                    </v-col>

                    <v-col cols=12>
                        <p class="form-title">Tipo de vivienda</p>
                        <p class="form-subtitle">Necesitamos este dato para calcular los impuestos</p>
                        <div :class="['options-container', { 'error-field': error_vivienda_nueva }]">
                            <div v-for="item in vars.new_build" :key="item.code" 
                                class="radio-button_container" 
                                @click="prescoringData.vivienda_nueva=item.code"
                            >
                            <div class="circle">
                                <div v-if="prescoringData.vivienda_nueva==item.code" class="isChecked"></div>
                            </div>
                            <label>{{item.name}}</label>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols=12>
                        <p class="form-title">Estado del proceso de compra</p>
                        <p class="form-subtitle"> Queremos ayudarte y conocer en qué momento del proceso te encuentras es relevante</p>
                        <div :class="['options-container', { 'error-field': error_estado_proceso }]">
                            <div v-for="item in vars.tipo_encontrada" :key="item.funnel100" 
                                class="radio-button_container" 
                                @click="prescoringData.estado_proceso=item.code"
                            >
                            <div class="circle">  
                                <div v-if="prescoringData.estado_proceso==item.code" class="isChecked"></div>
                            </div>
                            <label>{{item.funnel100}}</label>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols=12 v-if="prescoringData.precio_compra > 0">
                        <p class="form-title">¿Cuanto dinero puedes aportar a la operación?</p>
                        <p class="form-subtitle">Aquí lo importante es saber qué cantidad del precio de la vivienda hay que financiar, 
                            para saber cuáles son las mejores condiciones que te podemos ofrecer. Dependiendo de la aportación variará el porcentaje de financiación de la hipoteca.</p>
                        <div class="options-container">
                            <div class="radio-button_container" @click="handleOption('moreThanTwentyFivePercent')">
                                <div class="circle">  
                                    <div v-if="prescoringData.porcentaje==80" class="isChecked"></div>
                                </div>
                                <label>más de {{ twentyFivePercent(prescoringData.precio_compra) | currency }}€ (&lt;80%)</label>
                            </div>
                            <div class="radio-button_container" @click="handleOption('betweenFifteenAndTwentyFivePercent')">
                                <div class="circle">  
                                    <div v-if="prescoringData.porcentaje==90" class="isChecked"></div>
                                </div>
                                <label>entre {{ fifteenPercent(prescoringData.precio_compra) | currency }}€ y {{ twentyFivePercent(prescoringData.precio_compra) | currency }}€ (entre el 80-90%)</label>
                            </div>
                            <div class="radio-button_container" @click="handleOption('betweenTenAndFifteenPercent')">
                                <div class="circle">  
                                    <div v-if="prescoringData.producto_hipotecario=='myinvestor'" class="isChecked"></div>
                                </div>
                                <label>entre {{ tenPercent(prescoringData.precio_compra) | currency }}€ y {{ fifteenPercent(prescoringData.precio_compra) | currency }}€ (entre 91-99%)</label>
                            </div>
                            <div class="radio-button_container" @click="handleOption('lessThanTenPercent')">
                                <div class="circle">  
                                    <div v-if="prescoringData.producto_hipotecario=='helloteca'" class="isChecked"></div>
                                </div>
                                <label>menos de {{ tenPercent(prescoringData.precio_compra) | currency }}€ (100%)</label>
                            </div>
                        </div>
                    </v-col>

                </v-row>
            </v-form>
        </div>
        <div class="actions_container button-unique">
            <div clas="d-flex flex-column justify-end">
                <span v-if="isFilledOperation==false" class="filled-message ml-4" style="color:#ff5252"> Faltan datos obligatorios</span>
                <button class="button-primary_element mt-4" @click="goToNext">
                    <span class="button-primary_text">Continuar</span>
                </button>
            </div>
        </div>  
    </div>  
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import rulesMixin from '../../mixins/rulesMixin';


    export default {
        name: 'InitialView',
        mixins: [rulesMixin],
        data() {
            return {
                error_vivienda_nueva: false,
                error_estado_proceso: false
            }
        },
        mounted() {
            window.scrollTo(0, 0);
        },
        computed: {
            ...mapState([
                'vars',
                'isFilledOperation',
                'prescoringData',
                'activeTab',
                'origen'
            ]),            
        },
        methods: {
            ...mapActions([
                'setActiveTab'
            ]),
            ...mapMutations([
                'SET_IS_FILLED',
                'SET_PRESCORING_DATA',
                'SET_ACTIVE_TAB',
                'SET_ORIGEN'
            ]),
            setFilled(){
                this.SET_IS_FILLED(true)
            },
            tenPercent(value) {
                return value * 0.10;
            },
            fifteenPercent(value) {
                return value * 0.2;
            },
            twentyFivePercent(value) {
                return value * 0.3;
            }, 
            handleOption(option) {
                switch (option) {
                    case 'moreThanTwentyFivePercent':
                        this.SET_ORIGEN('funnel8090');               
                        this.prescoringData.porcentaje = 80;
                        this.prescoringData.producto_hipotecario = null;
                        break;
                    case 'betweenFifteenAndTwentyFivePercent':
                        this.SET_ORIGEN('funnel8090');               
                        this.prescoringData.porcentaje = 90;
                        this.prescoringData.producto_hipotecario = null;
                        break;
                    case 'betweenTenAndFifteenPercent':
                        this.SET_ORIGEN('funnel100');               
                        this.prescoringData.porcentaje = null; 
                        this.prescoringData.producto_hipotecario = 'myinvestor';
                        break;
                    case 'lessThanTenPercent':
                        this.SET_ORIGEN('funnel100');               
                        this.prescoringData.porcentaje = null; 
                        this.prescoringData.producto_hipotecario = 'helloteca';
                        break;
                    default:
                        break;
                }
            },
            goToNext(){
                let error = false;
                if(this.prescoringData.vivienda_nueva == null){
                    this.error_vivienda_nueva = true;
                    error = true;
                }else{
                    this.error_vivienda_nueva = false;
                }                

                if(this.prescoringData.estado_proceso == null){
                    this.error_estado_proceso = true;
                    error = true;
                }else{
                    this.error_estado_proceso = false;
                }

                if(this.$refs.form.validate() && !error){
                    let params = {};
                    params['precio_compra'] = this.prescoringData.precio_compra;
                    params['ccaa'] = this.prescoringData.ccaa;
                    params['vivienda_nueva'] = this.prescoringData.vivienda_nueva;
                    params['estado_proceso'] = this.prescoringData.estado_proceso;
                    params['producto_hipotecario'] =  this.prescoringData.producto_hipotecario;
                    params['porcentaje'] =  this.prescoringData.porcentaje;
                    params['origen'] = this.prescoringData.origen
                    // GTM
                    console.log("Lead_F3_Fase_1");
                    window.dataLayer.push({
                        'event': 'Lead_F3_Fase_1'
                    });
                    //console.log("params INITIAL", params)  
                    this.SET_PRESCORING_DATA(params); 
                    if(this.prescoringData.producto_hipotecario == 'helloteca')
                        this.SET_ACTIVE_TAB('AvalView');
                    else
                        this.SET_ACTIVE_TAB('MotivationView');
                }else {
                    return this.SET_IS_FILLED(false);
                }
            },       
        }
    }
</script>

<style>
    .button-unique{
        justify-content: end !important;
    }
</style>

<style scoped>
    .error-field{
        border-color: #ff5252;
    }
</style>

