<template>
  
  <div>
    <div v-if="loading" class="loader-container">
          <div class="loader-mask">
              <v-progress-circular 
                  color="#5A4FA2" 
                  indeterminate 
                  size="50">
              </v-progress-circular>
          </div>
      </div>
    <div v-else> 
      <p style="font-weight: 550;font-size: 20px;">Necesitamos verificar tu número de teléfono</p>
      <div class="bloque form-telefono">
        <p>Para poder continuar con el proceso y darte el mejor servicio posible, necesitamos verificar el número de teléfono al que te va a contactar una analista de nuestro equipo.</p>
        <p>Para ello te mandaremos un SMS con un código de verificación al número de teléfono que nos has indicado:</p>
        <div class="form-container">
            <v-form ref="form">
                <v-row >
                    <v-col cols=12>
                        <p class="form-title">Teléfono </p>
                        <v-text-field    
                            v-model="prescoringData.telefono"
                            outlined
                            :rules="[...ruleRequired,...rulePhone]"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <div class="actions_container button-unique">
            <div clas="d-flex flex-column justify-end">
                <button class="button-primary_element mt-4" @click="actualizarTelefono">
                    <span class="button-primary_text">Actualizar teléfono</span>
                </button>
            </div>
        </div>
        
        <div v-if="!codigoEnviado" class="d-flex flex-row align-center justify-center" style="margin-top: 25px">
            <button class="button-primary_element mt-4" style="padding-top: 15px; padding-bottom: 15px;" @click="solicitarCodigo">
                <span class="button-primary_text">Solicitar Código por SMS</span>
            </button>
        </div>
      </div>
      <div v-if="codigoEnviado">
        <div class="bloque form-codigo">
          <p>Introduce el código de 6 dígitos recibido:<span style="color: red; display: block; font-size: 12px">Asegúrate de que tu móvil no esté bloqueando el SMS</span></p>
          <div class="form-container">
              <v-form ref="form">
                  <v-row >
                      <v-col cols=12>
                          <v-text-field    
                              v-model="prescoringData.codigo"
                              outlined
                              :rules="[...ruleRequired]"
                              placeholder="Código de verificación"
                          ></v-text-field>
                      </v-col>
                  </v-row>
                  <v-row>
                    <span v-if="errorValidacion" class="filled-message ml-4" style="color:#ff5252">Parece que el código no es correcto o ha expirado, asegúrate de que el código introducido es correcto o solicita un nuevo código.</span>
                  </v-row>
              </v-form>
          </div>        
          <div class="actions_container button-unique">
              <div clas="d-flex flex-column justify-end">
                  <button class="button-primary_element mt-4" @click="verificarCodigo">
                      <span class="button-primary_text">Verificar código</span>
                  </button>
              </div>
          </div>
        </div>
        <div class="bloque form-reenvio">
          <p>Si no ha recibido el código de verificación compruebe que el teléfono indicado es correcto y podrá  solicitar otro código en <span style="color: red;">{{tiempoRestante }}</span> segundos</p>
          <div class="actions_container button-unique">
            <div clas="d-flex flex-column justify-end">
              <button class="button-primary_element mt-4" :disabled="tiempoRestante > 0" @click="solicitarCodigo">
                <span class="button-primary_text">Reenviar código</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import rulesMixin from '../../mixins/rulesMixin';


  export default {
      name: 'ValidacionTelefono',
      mixins: [rulesMixin],
      data() {
          return {
              totalTiempoEspera: 30,
              codigo: null,
              intervalo: 0,
              tiempoRestante: 0,
              errorValidacion: false,
              codigoEnviado: false
          }
      },
      mounted() {
        this.tiempoRestante = this.totalTiempoEspera;
          window.scrollTo(0, 0);
          this.intervalo = setInterval(this.actualizarContador, 1000);
      },
      computed: {
          ...mapState([
              'prescoringData',
              'loading',
              'debug'
          ]),            
      },
      methods: {
          ...mapActions([
            'solicitarCodigoVerificacion',
            'verificarCodigoVerificacion',
            'sendPrescoringData'
          ]),
          ...mapMutations([
              'SET_PRESCORING_DATA'
          ]),
          actualizarContador() {
            this.contador = this.tiempoRestante;
            if (this.tiempoRestante === 0) {
                clearInterval(this.intervalo);
            } else {
                this.tiempoRestante--;
            }
          },
          resetContador(){
            clearInterval(this.intervalo);
            this.tiempoRestante = this.totalTiempoEspera;
            this.intervalo = setInterval(this.actualizarContador, 1000);
          },
          async verificarCodigo(){
            this.errorValidacion = false;
            let res = true;
            if(this.debug == false) res = await this.verificarCodigoVerificacion();
            if(res){
              this.prescoringData.telefono_verificado = true;
              await this.sendPrescoringData(this.prescoringData);
            }else this.errorValidacion = true;
            this.resetContador();
          },
          async solicitarCodigo(){
            this.codigoEnviado = true;
            await this.solicitarCodigoVerificacion();
            this.resetContador();
          },
          actualizarTelefono() {
            let params = { telefono: this.prescoringData.telefono };
            this.SET_PRESCORING_DATA(params);
          }
      }
  }
</script>

<style>
  .button-unique{
      justify-content: end !important;
  }
</style>

<style scoped>
  .error-field{
      border-color: #ff5252;
  }
  .bloque{
    margin-top: 45px;
  }
</style>

