<template>
    <div>
        <div v-if="loading" class="loader-container">
            <div class="loader-mask">
                <v-progress-circular 
                    color="#5A4FA2" 
                    indeterminate 
                    size="50">
                </v-progress-circular>
            </div>
        </div>
        <div v-else>    
            <div class="form-container">
                <div style="text-align: center; padding-bottom: 12px; font-size: 18px">Tenemos los mejores intereses</div>
                <div class="table_type">
                    <div class="bloque-interes">
                        <p class="interes-titulo">FIJO</p>
                        <p class="interes">{{ formatNum2Decimals(mortgageDetails.fijo.interes) }}%</p>
                        <!-- <p style="font-weight: bold;"> {{ mortgageDetails.fijo.cuota }}€</p> -->
                    </div>
                    <div class="bloque-interes">
                        <p class="interes-titulo">MIXTO</p>
                        <p class="interes">{{ formatNum2Decimals(mortgageDetails.mixto.interes_fijo) }}% <span class="interes_anyos">({{ mortgageDetails.mixto.interes_fijo_anyos }} años)</span></p>
                        <!-- <p style="font-weight: bold; "> {{ mortgageDetails.mixto.cuota }}€</p> -->
                    </div>
                    <div class="bloque-interes">
                        <p class="interes-titulo">VARIABLE</p>
                        <p class="interes">Euribor + {{ formatNum2Decimals(mortgageDetails.variable.interes_diff) }}%</p>
                        <!-- <p style="font-weight: bold;"> {{ mortgageDetails.variable.cuota }}€</p> -->
                    </div>
                </div>

                <v-form ref="form">
                    <div style="margin: 40px 0">
                    <h4 class="holder_title">Titular 1</h4>
                    <v-row>
                        <v-col cols=12 sm="2" class="holder-column_age">
                            <p class="title-down">Edad </p>
                            <v-text-field 
                                v-model="prescoringData.titulares[0].edad"
                                outlined
                                dense
                                :rules="[...ruleRequired,...ruleNumber,...ruleMin18,...ruleMax70]"
                                v-on:keyup="setFilled"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5" class="holder-column_job_type">
                            <p class="title-down"> Tipo de contrato </p>
                            <v-autocomplete
                                attach
                                no-data-text="Selecciona un tipo de contrato"
                                v-model="prescoringData.titulares[0].contrato"
                                :items="vars.tipo_contrato"
                                outlined
                                item-text="name"
                                item-value="code"
                                dense
                                :rules="[...ruleRequired]"
                                v-on:keyup="isFilled=true"
                            />
                        </v-col>
                        <v-col cols="12" sm="5" class="holder-column_incorporation_date">
                            <p class="form-title_whitout_subtitle"> Antigüedad contrato (meses)</p>
                            <v-text-field
                                v-model="prescoringData.titulares[0].antiguedad"
                                outlined  
                                :rules="[...ruleRequired,...ruleNumber,...ruleMax720]"                              
                                v-on:keyup="isFilled=true"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <div class="add-button_container" v-if="this.newHolder == false && !dosTitulares">
                        <button class="add-button_circle" @click="addNewHolder"> 
                            <div class="add-button_plus">+</div></button>
                        <span class="add-button_text">Añadir otro titular</span>
                    </div>

                    <div v-if="this.newHolder == true || dosTitulares">
                        <h4 class="holder_title">Titular 2</h4>
                        <v-row>
                            <v-col cols=12 sm="2" class="holder-column_age">
                                <p class="title-down">Edad </p>
                                <v-text-field 
                                    v-model="prescoringData.titulares[1].edad"
                                    outlined
                                    dense
                                    :rules="[...ruleNumber,...ruleMin18,...ruleMax70]"
                                    v-on:keyup="setFilled"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5" class="holder-column_job_type">
                                <p class="title-down"> Tipo de contrato </p>
                                <v-autocomplete
                                    attach
                                    no-data-text="Selecciona un tipo de contrato"
                                    v-model="prescoringData.titulares[1].contrato"
                                    :items="vars.tipo_contrato"
                                    outlined
                                    item-text="name"
                                    item-value="code"
                                    dense
                                    v-on:keyup="isFilled=true"
                                />
                            </v-col>
                            <v-col cols="12" sm="5" class="holder-column_incorporation_date">
                                <p class="form-title_whitout_subtitle"> Antigüedad contrato (meses) </p>
                                <v-text-field
                                    v-model="prescoringData.titulares[1].antiguedad"
                                    outlined
                                    dense
                                    :rules="[...ruleNumber,...ruleMax720]"
                                    v-on:keyup="isFilled=true"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>

                </div>
                    <v-col cols="12" style="margin: 15px 0;">
                        <p class="form-title"> Ingresos de la unidad familiar </p>
                        <p class="form-subtitle">Indica todos los ingresos netos justificados en Bancos y/o Rentas, incluyendo pagas extras prorrateadas, alquileres y variables</p>
                        <v-currency-field
                            class="monthly"
                            v-model="prescoringData.total_ingresos"
                            placeholder="0"
                            outlined
                            append-icon="mdi-currency-eur"
                            :rules="[...ruleRequired,...ruleNumber]"
                            v-on:keyup="isFilled=true"
                        ></v-currency-field>
                    </v-col>

                    <v-col cols="12">
                        <p class="form-title">Cuotas mensuales de préstamos/hipoteca (si tiene) </p>
                        <p class="form-subtitle">Suma todos los préstamos o hipotecas que paguen todos los titulares, asi calcularemos el ratio de endeudamiento 
                            de la operación. Si alguna de las hipotecas que tienes ahora mismo se van a cancelar para la nueva hipoteca, no hace falta que las pongas.
                        </p>
                        <v-currency-field
                            class="monthly"
                            v-model="prescoringData.total_gastos"
                            outlined
                            append-icon="mdi-currency-eur"
                            :rules="[...ruleRequired,...ruleNumber]"
                            v-on:keyup="isFilled=true"
                        ></v-currency-field>
                    </v-col>

                    <v-col cols=12>
                        <p class="form-title">¿Tienes acceso al aval ICO?</p>
                        <div class="options-container">
                            <div class="radio-button_container" @click="setAvalIco(1)">
                                <div class="circle">  
                                    <div v-if="prescoringData.aval_ico==1" class="isChecked"></div>
                                </div>
                                <label>Si</label>
                            </div>
                            <div class="radio-button_container" @click="setAvalIco(0)">
                                <div class="circle">  
                                    <div v-if="prescoringData.aval_ico==0" class="isChecked"></div>
                                </div>
                                <label>No</label>
                            </div>
                            <div class="radio-button_container" @click="setAvalIco(null)">
                                <div class="circle">  
                                    <div v-if="prescoringData.aval_ico==null" class="isChecked"></div>
                                </div>
                                <label>No lo sé</label>
                            </div>
                        </div>
                    </v-col>

                    <div style="margin-top: 10px;  border-radius: 8px; padding: 10px  0;">
                    <v-col cols=12>
                        <p class="form-title_whitout_subtitle">Nombre y Apellidos </p>
                        <v-text-field 
                            v-model="prescoringData.nombre_completo"
                            outlined
                            dense
                            :rules="[...ruleRequired]"
                            v-on:keyup="setFilled"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <p class="form-title_whitout_subtitle"> Móvil </p>
                        <v-text-field    
                            v-model="prescoringData.telefono"
                            outlined
                            :rules="[...ruleRequired,...rulePhone]"
                            v-on:keyup="setFilled"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <p class="form-title_whitout_subtitle"> Email </p>
                        <v-text-field
                            v-model="prescoringData.email"
                            outlined
                            :rules="[...ruleRequired,...ruleEmail]"
                            v-on:keyup="setFilled"
                        ></v-text-field>       
                    </v-col>
                </div>
                <v-row>
                    <v-col cols="12" class="d-flex align-center">
                        <v-checkbox class="my-3" style="padding-left: 12px;" :rules="[v => !!v || 'Por favor, acepta los términos de uso y la política de privacidad']" >
                            <template v-slot:label>
                                <div style="font-size: 14px; color: #5A4FA2;">He leído y acepto los <a href="https://helloteca.com/terminos-y-condiciones" target="_blank" @click.stop>Términos de uso</a> y la <a href="https://helloteca.com/politica-privacidad" target="_blank" @click.stop>Política de privacidad</a></div>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
                </v-form>
            </div>

            <div class="actions_container" style="justify-content: center;">
                <div class="d-flex flex-column justify-center">
                    <span v-if="isFilledOperation==false" class="filled-message ml-4" style="color:#ff5252"> El formulario contiene errores, revisa todos los campos.</span>
                    <button class="button-secundary_element" @click="goToNext">
                        Conoce el resultado del estudio
                    </button>
                </div>
            </div>  
        </div>  
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import rulesMixin from '../../mixins/rulesMixin';

    export default {
        name: 'MotivationView',
        mixins: [rulesMixin],
        data() {
            return {
                calculatedRange: 0,
                newHolder: false,
                cuota_hipoteca: 0
            }
        },
        created(){
            this.loadMortgageDetails();
        },
        mounted() {
            window.scrollTo(0, 0);
            this.SET_TIPO_OPERACION('funnel8090');
        },
        computed: {
            ...mapState([
                'vars',
                'prescoringData',
                'isFilledOperation',
                'loading',
                'utmParams',
                'mortgageDetails',
                'scoringData',
                'debug'
            ]),
            dosTitulares(){
                return this.prescoringData.titulares[1].edad;
            }
        },
        methods: {
            ...mapActions([
                'sendPrescoringData',
                'getMortgageDetails',
                'solicitarCodigoVerificacion',
                'ComprobarVerificacion'
            ]),
            ...mapMutations([
                'SET_IS_FILLED',
                'SET_PRESCORING_DATA',
                'SET_LOADING',
                'SET_TIPO_OPERACION',
                'SET_ACTIVE_TAB'
            ]),
            setFilled(){
                this.SET_IS_FILLED(true)
            },
            formatNum2Decimals(value) {
                return value.toFixed(2);
            },
            async loadMortgageDetails() {
                this.SET_LOADING(true);
                let mortgageParams = {
                    origen: this.prescoringData.origen,
                    precio: this.prescoringData.precio_compra,
                    anyos: 30//this.calculaAnyosHipoteca(),
                };
                await this.getMortgageDetails(mortgageParams);
                this.updateLowestCuota();
                this.SET_LOADING(false);
            },
            getEdadMenorTitulares(){
                const edad1 = this.prescoringData.titulares[0].edad;
                const edad2 = this.prescoringData.titulares[1].edad;
                if(edad2 && edad2 < edad1) return edad2;
                else return edad1;
            },
            calculaAnyosHipoteca(){
                const edadMenor = this.getEdadMenorTitulares();
                let anyos = 30;
                const maxAnyos = 75 - edadMenor;
                if( maxAnyos > 30 ) anyos = 30;
                else anyos = maxAnyos;
                return anyos;
            },
            updateLowestCuota() {
                const { fijo, mixto, variable } = this.mortgageDetails;
                let fixedRate = fijo.cuota;
                let mixedRate = mixto.cuota;
                let variableRate = variable.cuota;

                this.cuota_hipoteca = Math.min(fixedRate, mixedRate, variableRate);
            },            
            addNewHolder(e){
                e.preventDefault();
                this.newHolder = true;
            },
            setAvalIco(value) {
                this.prescoringData.aval_ico = value;
            },
            async goToNext(){
                if(this.$refs.form.validate()){
                    let params = {
                        aportacion: null,
                        titulares: this.prescoringData.titulares.map(titular => ({
                            edad: titular.edad,
                            contrato: titular.contrato,
                            antiguedad: titular.antiguedad
                        })),
                        total_ingresos: this.prescoringData.total_ingresos,
                        total_gastos: this.prescoringData.total_gastos,
                        aval_ico: this.prescoringData.aval_ico,
                        nombre_completo: this.prescoringData.nombre_completo,
                        telefono: this.prescoringData.telefono,
                        email: this.prescoringData.email,
                        utms: {
                            utm_source: this.utmParams.utm_source || "",
                            utm_medium: this.utmParams.utm_medium || "",
                            utm_campaign: this.utmParams.utm_campaign || "",
                            utm_term: this.utmParams.utm_term || "",
                            utm_content: this.utmParams.utm_content || "",
                            gclid: this.utmParams.gclid || ""
                        }
                    };
                    this.SET_PRESCORING_DATA(params);

                    const campaignsParaVerificar = ['Hipoteca_100','comparador'];
                    if(typeof this.scoringData.id_prescoring === 'undefined' && campaignsParaVerificar.includes(this.prescoringData.utms?.utm_campaign)){
                        // Comprobamos si está validado previamente el lead
                        this.prescoringData.telefono_verificado = await this.ComprobarVerificacion();
                        // Ir a Validación
                        if(this.prescoringData.telefono_verificado == false){
                            // No está verificado enviamos a pantalla verificar
                            this.SET_ACTIVE_TAB('ValidacionTelefono');
                        }
                        // Ir al final (generar lead)
                        else{
                            this.enviarPrescoring();
                        }
                    }
                    // Ir al final (generar lead)
                    else{
                        this.enviarPrescoring();
                    }
                }else {
                    return this.SET_IS_FILLED(false);
                }
            },
            async enviarPrescoring(){
                this.SET_LOADING(true);
                try {
                    // GTM
                    console.log("Lead_F3_Fase_2");
                    window.dataLayer.push({
                        'event': 'Lead_F3_Fase_2',
                        'email': this.prescoringData.email,
                        'phone': this.prescoringData.telefono
                    });
                    await this.sendPrescoringData(this.prescoringData);
                    
                } finally {
                    this.SET_LOADING(false);
                }
            }
        },
        watch: {
            // 'prescoringData.titulares': {
            //     handler() {
            //         this.updateAge();
            //     },
            //     deep: true
            // },
            loading(newVal) {
                if (newVal) window.scrollTo(0, 0);            
            }
        }
    }
</script>

<style>
/* CUSTOMATIZA SLIDE DE EDAD */
    .custom-range {
        appearance:none;
        -webkit-appearance: none;
        width: 100%;
        height: 8px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        transition: opacity 0.2s;
    }
    .custom-range:hover {
        opacity: 1;
    }
    .custom-range::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: #5A4FA2;
        cursor: pointer;
        border-radius: 50%;
        }
    .custom-range::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: #5A4FA2;
        cursor: pointer;
        border-radius: 50%;
    }    
    
    .important-msg{
        border-radius: 8px;
        padding: 8px;
        margin: 8px 0;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        /* background-color:#12868D;
        color: white;
        border: 2px solid; */
        color: #5A4FA2;
        border: 2px solid #5A4FA2;
    }
    @media (min-width:750px){
        .important-msg{
            padding: 12px;
            margin: 12px;
            margin-bottom: 20px;
            font-size: 26px;
        }
    }
    .holder_title{
        font-size: 20px;
        font-weight: 500px;
        margin: 26px 0 12px;
    }
    .title-down{
        color:#5A4FA2;
        font-weight: 550;
        margin-bottom:33px !important;
    }    
    .holder-column_age{
        padding: 12px 6px 12px 24px;
    }
    .holder-column_job_type{
        padding: 12px 6px;
    }

    .holder-column_incorporation_date{
        padding: 12px 24px 12px 6px;
    }

    @media (max-width: 600px){
        .holder-column_age{
            padding: 12px 24px;
        }
        .holder-column_job_type{
            padding: 12px 24px;
        }
        .holder-column_incorporation_date{
            padding: 12px 24px;
        }
        .title-down{
            color:#5A4FA2;
            font-weight: 550;
            margin-bottom:8px !important;
        }
    }

/* BOTON DE AÑADIR OTRO TITULAR */
    .add-button_container{
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .add-button_circle{
        width: 35px;
        height: 35px;
        border: 1px solid #5A4FA2;
        border-radius: 100%;
        margin-right: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .add-button_plus{
        color:#5A4FA2;
        font-size: 30px;
        font-weight: 100;
    }
    .add-button_text{
        color:#5A4FA2;
        font-size: 14px;
    }
    @media (min-width: 600px){
        .add-button_circle{
            width: 45px;
            height: 45px;
            margin-right: 8px;
        }
        .add-button_plus{
            font-size: 35px;
        }
        .add-button_text{
            font-size: 16px;
        }
    }
    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }
    .isChecked{
        background-color: #5A4FA2;
    }
    .table_type {
        display:flex; 
        flex-direction: row; 
        justify-content: center;
        gap: 20px;
        margin: auto;
    }
    .bloque-interes{
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 18px;
        font-weight: 500;
        color: #5A4FA2;
        width: 33%;
        background-color: #e8fdff;
        border-radius: 15px;
        align-items: center;
        text-align: center;
        border: 2px solid #5A4FA2;
        padding: 20px;
    }
    .bloque-interes p{
        margin: 0;
    }
    .bloque-interes p.interes-titulo{
        font-weight: bold;
    }
    .bloque-interes p.interes{
        font-size: 16px;
    }
    .bloque-interes p.cuota{
        margin-top : 10px;
        font-weight: bold;
        font-size: 20px;
    }

    @media (max-width: 600px){
        .bloque-interes{
            padding: 15px;
        }
        .bloque-interes p.interes-titulo{
            font-size: 16px;
        }
        .bloque-interes p.interes{
            font-size: 14px;
        }
    }

    @media (max-width: 510px){
        .bloque-interes .interes_anyos{
            display: block;
        }
    }

    @media (max-width: 410px){
        .bloque-interes .interes_anyos{
            font-size: 12px;
        }
    }    
</style>