export default {
    data: ()=> {
        return {
            ruleRequired: [
                v => !!v || 'Campo obligatorio'
            ],
            ruleRequiredFor01: [
                v => (!!v || v=== 0) || 'Campo obligatorio'
            ],
            ruleRequiredWith0: [
                v =>{
                    if(v === 0) return true;
                    else{
                        if(!v){
                            return 'Campo obligatorio'
                        }
                        if(v < 0){  
                            return 'El valor debe ser 0 o superior'
                        }
                        return true
                    }
                } 
            ],
            ruleLegal: [
                v => !!v || 'Por favor, acepta las condiciones del servicio'
            ],
            ruleMin: [
                v => {
                    return v && v.length >= 3 || 'Debe tener al menos 3 caracteres'
                },
            ],
            ruleSlug: [
                v => {
                    if(v && v.length > 0){
                        // eslint-disable-next-line no-useless-escape
                        let regx = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;
                        return regx.test(v) || 'Formato url icorrecto';
                    }else{
                        return true;
                    }
                }
            ],
            rulePhone: [
                v => {
                    if(v && v.length > 0){
                        // debe empezar con 6 o 7, tener como máximo 9 dígitos, puede incluir separadores como espacios, puntos o guiones.
                        // let regx = /^\(?([6-7][0-9]{2})\)?([ .-]?)([0-9]{0,3})\2([0-9]{0,3})$/;
                        // return regx.test(v) && v.replace(/[ .-]/g, '').length <= 9 || 'Formato de teléfono incorrecto';
                        let regx = /^(6|7)(?!\1{8})\d{8}$/;
                        return regx.test(v) || 'Formato de teléfono incorrecto';                        
                    } else {
                        return true;
                    }
                }
            ],              
            ruleDate: [
                v => {
                    if(v && v.length > 0){
                        // eslint-disable-next-line no-useless-escape
                        let regx = /^([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])\2(\d{4})$/;
                        return regx.test(v) || 'Formato de fecha incorrecto DD/MM/AAAA';
                    }else{
                        return true;
                    }
                }
            ],
            ruleEmail: [
                v => {
                    if(v && v.length > 0){
                        // eslint-disable-next-line no-useless-escape
                        let regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return regx.test(v) || 'Formato de email incorrecto';
                    }else{
                        return true;
                    }
                }
            ],
            ruleMin10000: [
                v => {
                    if(v && v.length > 0){
                        return v && (parseInt(v.replace(/\./g, ''), 10) >= 10000) || `La cantidad debe ser superior a 10.000`;
                    }else {
                        return true;
                    }
                },
            ],
            ruleMin30k: [
                v => {
                    if(v && v.length > 0){
                        return v && (parseInt(v.replace(/\./g, ''), 30) >= 30000) || `La cantidad debe ser superior a 30.000`;
                    }else {
                        return true;
                    }
                },
            ],
            ruleMin0: [
                v => {
                    return v && ( v > 0) || 'La cantidad debe ser superior a cero'
                },
            ],
            ruleMin10: [
                v => {
                    return v && ( v >= 10) || 'La cantidad debe ser superior a 10'
                },
            ],
            ruleMin18: [
                v => {
                    return v && ( v >= 18) || 'La cantidad debe ser superior a 18'
                },
            ],
            ruleMax100M: [
                v => {
                    if(v && v.length > 0){
                        return v && (parseInt(v.replace(/\./g, ''), 10) <= 100000000) || 'La cantidad debe ser inferior a 100.000.000'
                    }else {
                        return true;
                    }
                },
            ],
            ruleMax50: [
                v => {
                    return v && (v <= 2 || v <= 48) || 'La cantidad debe ser inferior a 48'
                },
            ],
            ruleMax70: [
                v => {
                    return v && (v <= 2 || v <= 70) || 'La cantidad debe ser inferior a 70'
                },
            ],            
            ruleNumber: [
                v => {
                    return v && !isNaN(v)  || 'Solo números'
                },
            ],
            ruleCIF: [
                v => {
                    if(v && v.length > 0){
                        // eslint-disable-next-line no-useless-escape
                        let regx = /([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/;
                        return regx.test(v) || 'Formato incorrecto';
                    }else{
                        return true;
                    }
                }
            ],
            ruleDateBeforeToday:[
                v => {
                    let today = new Date().getTime();
                    let futureValue = new Date(v).getTime();
                    if(v && v.length > 0 && futureValue > today){
                        return 'La fecha no puede ser posterior al día de hoy';
                    }else{
                        return true;
                    }
                }
            ],
            ruleMax720: [
                v => {
                    if (v && v.length > 0) {
                        return v && (parseInt(v, 10) <= 720) || `La cantidad no debe ser superior a 720 meses`;
                    } else {
                        return true;
                    }
                },
            ],
        }       
    }
};